/**
 * @file
 * Component for displaying the treatment details when it is viewed
 * @author <akel.ms93@gmail.com>
 */

import React, { useState, useEffect } from "react";
import Study from "./Study";
import LinksMenuEn from "./LinksMenuEn";
import LinksMenuFr from "./LinksMenuFr";
import TrafficLight from "../UI/TrafficLight";
import Header from "../Home/Header";
import FavouriteIcon from "../UI/Icons/FavouriteIcon";
import { Button } from "reactstrap";
import Footer from "../Footer";
import printJS from "print-js";
import {
  baseUrl,
  getRequest,
  postRequest,
  deleteRequest,
} from "../../API/ApiHandler";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import placeholder from "../../img/default_placeholder.png";
import IdleComponent from "../IdleComponent";
import "./TreatmentDetails.css";

const TreatmentDetails = (props) => {
  const [treatment, setTreatment] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [studies, setStudies] = useState([]);
  const [webLinks, setWebLinks] = useState([]);
  const [videoLinks, setVideoLinks] = useState([]);
  const [navTo, setNavTo] = useState("");
  const [open, setOpen] = useState([]);
  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((accordionId) => accordionId !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const toggleFavourite = (ev) => {
    const path = "/userFavourites";
    const input = { treatment_id: treatment.id };
    if (ev.target.getAttribute("data-active") === "false") {
      postRequest(path, input);
      setIsSelected(true);
    } else {
      deleteRequest(path, input);
      setIsSelected(false);
    }
  };

  const getWebLinks = async () => {
    let data = await getRequest(`/learn/${treatment.id}`);
    if (data.length) setWebLinks(data);
  };

  const getVideoLinks = async () => {
    let data = await getRequest(`/videos/${treatment.id}`);
    if (data.length) setVideoLinks(data);
  };

  const getStudies = async () => {
    let data = await getRequest(`/studies/${treatment.id}`);
    if (data.length) setStudies(data);
  };

  function getLinksByNationAndLang(nation, lang) {
    let links = [];
    webLinks.map((web) => {
      if (
        web.nationality.trim() === nation &&
        web.language.trim() === lang &&
        web.is_app === false
      ) {
        links.push(web);
      }
    });
    return links;
  }

  function getNumOfResourcesByLang(lang) {
    let numOfLinks = 0;
    webLinks.map((web) => {
      if (web.language === lang) {
        numOfLinks += 1;
      }
    });
    videoLinks.map((video) => {
      if (video.language === lang) {
        numOfLinks += 1;
      }
    });
    return numOfLinks;
  }

  function getAppsByLang(lang) {
    let links = [];
    webLinks.map((web) => {
      if (web.language.trim() === lang && web.is_app === true) {
        links.push(web);
      }
    });
    return links;
  }

  function getVidsByLang(lang) {
    let vids = [];
    videoLinks.map((video) => {
      if (video.language.trim() === lang) {
        vids.push(video);
      }
    });
    return vids;
  }

  const handleStepperNav = (to) => {
    props.data.history.push(to);
  };

  const cadLinks = {
    name: "Canadian Web Pages",
    name_fr: "Pages web canadiennes",
    id: "cadPages",
    links: getLinksByNationAndLang("Canadian", "English"),
    links_fr: getLinksByNationAndLang("Canadian", "English").concat(
      getLinksByNationAndLang("Canadian", "French")
    ),
  };

  const ameriLinks = {
    name: "American Web Pages",
    name_fr: "Pages web américaines",
    id: "ameriPages",
    links: getLinksByNationAndLang("American", "English"),
    links_fr: getLinksByNationAndLang("American", "English").concat(
      getLinksByNationAndLang("American", "French")
    ),
  };

  const otherLinks = {
    name: "Web pages from other countries",
    name_fr: "Pages web d'autres pays",
    id: "otherPages",
    links: getLinksByNationAndLang("Other", "English"),
    links_fr: getLinksByNationAndLang("Other", "English").concat(
      getLinksByNationAndLang("Other", "French")
    ),
  };

  const vidLinks = {
    name: "Videos",
    name_fr: "Vidéos",
    id: "videos",
    links: getVidsByLang("English"),
    links_fr: getVidsByLang("English").concat(getVidsByLang("French")),
  };

  const appLinks = {
    name: "Apps",
    name_fr: "Applis",
    id: "apps",
    links: getAppsByLang("English"),
    links_fr: getAppsByLang("English").concat(getAppsByLang("French")),
  };

  const allLinks = [cadLinks, ameriLinks, otherLinks, vidLinks, appLinks];

  useEffect(() => {
    setTreatment(props.data.location.state[0]);
    setIsSelected(props.data.location.state[1]);
  }, []);

  useEffect(() => {
    if (treatment) {
      getWebLinks();
      getVideoLinks();
      getStudies();
    }
  }, [treatment]);

  // const downloadPDF = async () => {
  //   // const res = await getRequest(`/pdf/Night`)
  //   const res = await fetch(`http://localhost:3030/pdf/${treatment.name}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/pdf",
  //       "Content-Type": "application/json",
  //       mode: "cors",
  //       credentials: "include",
  //     },
  //   });
  //   const blob = await res.blob();

  //   // var fileURL = URL.createObjectURL(blob);
  //   //     window.open(fileURL);
  //   download(blob, `${treatment.name}.pdf`);
  //   // console.log(treatment.name)
  // };

  const downloadPDF = async () => {
    printJS({
      printable: "printForm",
      type: "html",
      targetStyles: ["*"],
      maxWidth: "1200",
      documentTitle: lang === "English" ? treatment.name : treatment.fr_name,
    });
  };

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  const makePage = () => {
    return (
      <React.Fragment>
        <div className="wrapper">
          <IdleComponent history={props.data.history} />
          <div className="page-header">
            <Header
              current={props.data}
              handleNav={handleStepperNav}
              setNavTo={setNavTo}
            />
          </div>
          <div className="sub-header">
            <div className="subheader-content-treatments">
              <h3>
                {lang === "English"
                  ? "Treatment details"
                  : "Détails du traitement"}
              </h3>
            </div>
          </div>
          <div className="body-container treatment_details">
            <div className="back-button-container">
              <Button
                className="next-btn"
                onClick={() => {
                  props.data.history.goBack();
                }}
              >
                {lang === "English" ? "Go Back" : "Retourner"}
              </Button>
            </div>
            <div className="box-container" id="printForm">
              {/* Image and description */}
              <div id="image_and_description">
                <img
                  alt={treatment.name}
                  src={
                    treatment.image
                      ? `${baseUrl}/${treatment.image}`
                      : placeholder
                  }
                />
                <div className="description">
                  <div className="download-btn-top">
                    <Button className="next-btn" onClick={downloadPDF}>
                      {lang === "English" ? "Download" : "Télécharger"}
                    </Button>
                  </div>
                  <div className="description-title">
                    <h2>
                      {lang === "English" ? treatment.name : treatment.fr_name}
                    </h2>
                    <span>
                      <FavouriteIcon
                        toggleFav={toggleFavourite}
                        isFav={isSelected}
                      />
                    </span>
                  </div>
                  <p>
                    {lang === "English"
                      ? treatment.description
                      : treatment.fr_description}
                  </p>
                </div>
              </div>

              <div id="traffic_and_description">
                <div className="traffic-light-container">
                  <TrafficLight level={treatment.traffic_level} />
                </div>
                <div className="traffic-description">
                  <p>
                    {lang === "English"
                      ? treatment.traffic_description
                      : treatment.fr_traffic_description}
                  </p>
                </div>
              </div>

              <div className="treatment_details_overview">
                <h3 id="experts_suggest">
                  {lang === "English"
                    ? "What do experts suggest?"
                    : "Que suggèrent les experts?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.experts_suggest
                    : treatment.fr_experts_suggest}
                </p>
              </div>

              {/* Studies section */}
              <div className="research_heading">
                <h3>
                  {lang === "English"
                    ? "What does the research say?"
                    : "Que dit la recherche?"}
                </h3>
              </div>
              {studies && (
                <div className="treatment_details_study_container">
                  {studies.map((study, i) => (
                    <Study key={study.id} study={study} studyNo={i + 1} />
                  ))}
                </div>
              )}

              {/* treatment details section */}
              <div className="treatment-details-texts">
                <h3>
                  {lang === "English"
                    ? "How can I use this treatment?"
                    : "Comment puis-je utiliser ce traitement?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.how_use
                    : treatment.fr_how_use}
                </p>
                <h3>
                  {lang === "English"
                    ? "How soon will I feel better?"
                    : "Quand vais-je me sentir mieux?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.how_soon
                    : treatment.fr_how_soon}
                </p>
                <h3>
                  {lang === "English"
                    ? "How much does this treatment cost?"
                    : "Combien coûte ce traitement?"}
                </h3>
                <p>{lang === "English" ? treatment.cost : treatment.fr_cost}</p>
                <h3>
                  {lang === "English"
                    ? "Where can I get this treatment?"
                    : "Où puis-je obtenir ce traitement?"}
                </h3>
                <p>
                  {lang === "English" ? treatment.where : treatment.fr_where}
                </p>
                <h3>
                  {lang === "English"
                    ? "What else should I consider?"
                    : "Que dois-je envisager d'autre?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.consider
                    : treatment.fr_consider}
                </p>

                {getNumOfResourcesByLang("English") > 0 && lang === "English" ? (
                  <div className="treatment_details_links">
                    <h3>Learn more:</h3>
                    <Accordion open={open} toggle={toggle}>
                      {allLinks.map((link) =>
                        lang === "English" && link.links.length > 0 ? (
                          <LinksMenuEn
                            link={link}
                            open={open}
                            toggle={toggle}
                          />
                        ) : (
                          ""
                        )
                      )}
                    </Accordion>
                  </div>
                ) : (
                  ""
                )}

                {webLinks.length + videoLinks.length > 0 &&
                lang === "French" ? (
                  <div className="treatment_details_links">
                    <h3>En savoir plus:</h3>
                    <Accordion open={open} toggle={toggle}>
                      {allLinks.map((link) =>
                        lang === "French" && link.links_fr.length > 0 ? (
                          <LinksMenuFr
                            link={link}
                            open={open}
                            toggle={toggle}
                          />
                        ) : (
                          ""
                        )
                      )}
                    </Accordion>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="save-as-favourite">
                <h5>
                  {lang === "English"
                    ? "Save to favourites"
                    : "Enregistrer dans les favoris"}
                </h5>
                <span>
                  <FavouriteIcon
                    toggleFav={toggleFavourite}
                    isFav={isSelected}
                  />
                </span>
                <div className="download-btn-btm">
                  <Button className="next-btn" onClick={downloadPDF}>
                    {lang === "English" ? "Download" : "Télécharger"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="back-button-container">
              <Button
                className="next-btn"
                onClick={() => props.data.history.goBack()}
              >
                {lang === "English" ? "Go Back" : "Retourner"}
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  };

  return <div id="treatment_details_div">{treatment && makePage()}</div>;
};

export default TreatmentDetails;
