import React, { useState, useEffect } from 'react'
import Header from "../../../components/Home/Header";
import UserNav from '../../../components/UserDash/UserNav'
import { BarChart, CartesianGrid, XAxis, YAxis, Cell, Tooltip, Bar, ResponsiveContainer } from 'recharts'
import { getRequest } from "../../../API/ApiHandler"

import Subheader from '../../../components/UI/Subheader/Subheader';
import { logout, getUserType } from '../../../components/AuthenticationService';
import { Button } from 'reactstrap';
import { NavLink as RRNav } from "react-router-dom"
import BottomTabNavReview from '../../../components/Review/BottomTabNavReview';

import '../UserPanel.css'
import IdleComponent from '../../../components/IdleComponent'

//Get value of language from local storage
let lang = localStorage.getItem("language")
var fontLimit = 17;
var usedElements = 1;
var medElements = 1;
var plannedElements = 1;

// this maps the names to shorter more understandable names to show them in the bar charts.
const nameMap = {
  // English Names
  "NSAIDs in Pills (For example Advil®, Aleve®, Motrin® and Naprosyn®)": "NSAIDs Pills",
  "Acetaminophen (Tylenol®)": "Acetaminophen",
  "Heat": "Heat",
  "Custom-Made Foot Orthotics": "Foot Orthotics",
  "Massage": "Massage",
  "Yoga": "Yoga",
  "Osteopathic Manipulation": "Osteopathic Manipulation",
  "Educational Website": "Educational Website",
  "Wrist Splints Worn at Night": "Wrist Splints",
  "Pilates": "Pilates",
  "Cardio": "Cardio",
  "Occupational Therapy Interventions": "Occupational Therapy",
  "Chiropractic": "Chiropractic",
  "Distraction": "Distraction",
  "Night Mouth Guards": "Night Mouth Guards",
  "Water Exercises": "Water Exercises",
  "Individualized Exercise Program": "Individualized Exercise",
  "Stretching": "Stretching",
  "Joint Protection Program": "Joint Protection",
  "Relaxation": "Relaxation",
  "Physiotherapy Interventions": "Physiotherapy",
  "Cold": "Cold",
  "Acupuncture": "Acupuncture",
  "Sleep Routine": "Sleep Routine",
  "Hypnosis": "Hypnosis",
  "Mindfulness": "Mindfulness",
  "Cognitive-Behavioural Therapy": "Cognitive Therapy",
  "NSAIDs in Creams (Voltaren®)": "NSAIDs Creams",
  "Opioids": "Opioids",
  "Cannabis products/Marijuana": "Cannabis",
  "Acetylsalicylic Acid (Aspirin®)": "Acetylsalicylic Acid",
  "Healthy Diet": "Healthy Diet",
  "Mediterranean Diet": "Mediterranean Diet",
  "Fish Oil (Omega-3)": "Fish Oil",
  "Glucosamine Hydrochloride": "Glucosamine Hydrochloride",
  "Vitamin D": "Vitamin D",
  "Calcium": "Calcium",
  "Vegetarian & Vegan Diets": "Vegan Diets",
  "Gluten-Free Diets": "Gluten-Free Diets",

  // French Names
  "AINS en comprimés (Par exemple Advil®, Aleve®, Motrin® et Naprosyn®)": "AINS en comprimés",
  "Acétaminophène (Tylenol®)": "Acétaminophène",
  "Chaleur": "Chaleur",
  "Orthèses plantaires sur mesure": "Orthèses plantaires",
  "Massothérapie": "Massothérapie",
  "Ostéopathie": "Ostéopathie",
  "Site Web éducatif": "Site Web éducatif",
  "Attelles de poignet portées la nuit": "Attelles de poignet",
  "Exercices de cardio": "Exercices de cardio",
  "Interventions d’ergothérapie": "Ergothérapie",
  "Chiropractie": "Chiropractie",
  "Plaque occlusale portée la nuit": "Plaque occlusale",
  "Exercice dans l’eau": "Exercice dans l’eau",
  "Programme d’exercices personnalisé": "Programme d’exercices",
  "Étirements": "Étirements",
  "Programme de protection des articulations": "Protection des articulations",
  "Interventions de physiothérapie": "Physiothérapie",
  "Froid": "Froid",
  "Routine de sommeil": "Routine de sommeil",
  "Hypnose": "Hypnose",
  "Méditation pleine conscience": "Méditation",
  "Thérapie cognitivocomportementale": "Thérapie cognitive",
  "AINS sous forme de crème (Voltaren®)": "AINS en crème",
  "Opioïdes": "Opioïdes",
  "Produits du Cannabis/Marijuana": "Cannabis",
  "Acide acétylsalicylique (Aspirin®)": "Acide acétylsalicylique",
  "Saine alimentation": "Saine alimentation",
  "Diète méditerranéenne": "Diète méditerranéenne",
  "Huile de poisson (oméga-3)": "Huile de poisson",
  "Chlorhydrate de glucosamine": "Chlorhydrate de glucosamine",
  "Vitamine D": "Vitamine D",
  "Régimes végétariens et végétaliens": "Régimes végétariens",
  "Régimes sans gluten": "Régimes sans gluten",
  "Corticosteroid joint injections (in the past three months) ":"Corticosteroid injections"
};

function transformName(name, nameMap) {
  // Check if the name exists in the provided map
  if (nameMap.hasOwnProperty(name)) {
    return nameMap[name];
  }

  // Fallback to cleaning the name by removing anything in parentheses
  return name.replace(/\(.*?\)/g, "").trim();
}


const TreatmentAvg = (props) => {
  const [user, setUser] = useState()
  const [logs, setLogs] = useState([])
  const [rawUsedLogs, setRawUsedLogs] = useState([])
  const [rawPlannedLogs, setRawPlannedLogs] = useState([])
  const [rawMedLogs, setRawMedLogs] = useState([])

  const [date1Used, setDate1Used] = useState('default')
  const [date2Used, setDate2Used] = useState('default')

  const [date1Med, setDate1Med] = useState('default');
  const [date2Med, setDate2Med] = useState('default');

  const [date1Planned, setDate1Planned] = useState('default');
  const [date2Planned, setDate2Planned] = useState('default');

  const [usedDates, setUsedDates] = useState([])
  const [usedExport, setUsedExport] = useState([])
  const [usedLimited, setUsedLimited] = useState([])

  const [medDates, setMedDates] = useState([])
  const [medExport, setMedExport] = useState([])
  const [medLimited, setMedLimited] = useState([])

  const [saved, setSaved] = useState(true)
  const [navBy, setNavBy] = useState('')
  const [displayNavDialog, setDisplayNavDialog] = useState(false)

  const [plannedDates, setPlannedDates] = useState([])
  const [plannedExport, setPlannedExport] = useState([])
  const [plannedLimit, setPlannedLimit] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [navTo, setNavTo] = useState('')

  const handleStepperNav = to => {
    props.history.push(to)

  }
      
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState('default');

  const predefinedDateRanges = [
    { label: lang === "English" ? "Last Week" : "Semaine dernière", range: () => [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()] },
    { label: lang === "English" ? "Last 2 Weeks" : "Les 2 dernières semaines", range: () => [new Date(new Date().setDate(new Date().getDate() - 14)), new Date()] },
    { label: lang === "English" ? "Last 3 Weeks" : "Les 3 dernières semaines", range: () => [new Date(new Date().setDate(new Date().getDate() - 21)), new Date()] },
    { label: lang === "English" ? "Last Month" : "Mois dernier", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()] },
    { label: lang === "English" ? "Last 3 Months" : "Les 3 derniers mois", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()] },
    { label: lang === "English" ? "Last 6 Months" : "Les 6 derniers mois", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 6)), new Date()] },
    { label: lang === "English" ? "Last 9 Months" : "Les 9 derniers mois", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 9)), new Date()] },
    { label: lang === "English" ? "Last Year" : "Année dernière", range: () => [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()] },
    { label: lang === "English" ? "Last 2 Years" : "Les 2 dernières années", range: () => [new Date(new Date().setFullYear(new Date().getFullYear() - 2)), new Date()] },
  ];

  const getUser = async () => {
    let response = await getRequest('/auth/users/current/me')
    let user = ''
    if (response) {
      user = response.data.id
      setUser(user)
      getLogs(user)
    }
  }

  const getLogs = async (user) => {
    const data = await getRequest(`/logs/${user}`)
    if (data) {
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      let updatedLog = data.map(log => {
        const options = { timeStyle: 'short', timeZone: 'UTC' }
        const time = new Date(log.createdAt).toLocaleTimeString('en-CA', options)
        const date = new Date(log.createdAt).toLocaleDateString('en-CA')
        log.date = date
        log.time = time
        return log
      })
      setLogs(updatedLog)
      getUsed(updatedLog)
      getPlanned(updatedLog)
      getMed(updatedLog)
    }
    setIsLoading(false)
  }

  const getUsed = (updatedLog) => {
    const exportList = updatedLog.map(el => {
      if (el.step_one !== null) {
        let log = {
          "used": el.step_one ? JSON.parse(el.step_one).other_treatments : '',
          "date": el.date
        }
        return log
      }
    })
    setUsedExport(exportList)
    removeNullUsed(exportList)
  }

  const getMed = (updatedLog) => {
    const exportList = updatedLog.map(el => {
      if (el.step_one !== null) {
        let log = {
          "med": el.step_one ? JSON.parse(el.step_one).selected_meds : '',
          "date": el.date
        }
        return log
      }
    })
    setMedExport(exportList)
    removeNullMed(exportList)
  }

  const getPlanned = (updatedLog) => {
    const exportList = updatedLog.map(el => {
      if (el.plan !== null) {
        let log = {
          "planned": el.plan ? el.plan : '',
          "date": el.date
        }
        return log
      }
    })
    setPlannedExport(exportList)
    removeNullPlanned(exportList)
  }

  const removeNullUsed = (exportList) => {
    exportList = exportList.filter(function (element) {
      return element !== undefined
    })
    setUsedExport(exportList)
    setRawUsedLogs(exportList)
    getDatesUsed(exportList)
    usedCount(exportList)
    return exportList
  }

  const getDatesUsed = (exportList) => {
    let dates = [];
    exportList.forEach((el) => {
      dates = [el.date, ...dates]
    });

    dates = [...new Set(dates)].sort((a, b) => new Date(b) - new Date(a));

    setUsedDates([...dates]);
  }

  const removeNullMed = (exportList) => {
    exportList = exportList.filter(function (element) {
      return element !== undefined
    })
    setMedExport(exportList)
    setRawMedLogs(exportList)
    getDatesMed(exportList)
    medCount(exportList)
    return exportList
  }

  const getDatesMed = (exportList) => {
    let dates = [];
    exportList.forEach((el) => {
      dates = [el.date, ...dates]
    });
    dates = [...new Set(dates)].sort((a, b) => new Date(b) - new Date(a));
    setMedDates([...dates]); 
  }

  const removeNullPlanned = (exportList) => {
    exportList = exportList.filter(function (element) {
      return element !== undefined
    })
    setPlannedExport(exportList)
    setRawPlannedLogs(exportList)
    planCount(exportList)
    getPlannedDates(exportList)
    return exportList
  }

  const getPlannedDates = (exportList) => {
    let dates = []
    exportList.forEach((el) => {
      dates = [el.date, ...dates]
    });
    dates = [...new Set(dates)].sort((a, b) => new Date(b) - new Date(a));
    setPlannedDates([...dates]);     
  }

  const planCount = (list) => {
    let counts = []
    for (var i = 0; i < list.length; i++) {
      for (var j = 0; j < JSON.parse(list[i].planned).length; j++) {
        if (JSON.parse(list[i].planned)[j] !== null) {
          counts.push(JSON.parse(list[i].planned)[j])
        }
      }
    }

    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output

    setPlannedExport(output)
    setPlannedLimit(output)
    return output
  }

  const usedCount = (list) => {
    let counts = []
    for (var i = 0; i < list.length; i++) {
      for (var j = 0; j < list[i].used.length; j++) {
        if (list[i].used.length !== 0) {
          counts.push(list[i].used[j])
        }
      }
    }
    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output

    setUsedExport(output)
    setUsedLimited(output)
    return output
  }

  const medCount = (list) => {
    let counts = []
    for (var i = 0; i < list.length; i++) {
      for (var j = 0; j < list[i].med.length; j++) {
        if (list[i].med.length !== 0) {
          counts.push(list[i].med[j])
        }
      }
    }
    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : en_output //no french names in the logs

    setMedExport(output)
    setMedLimited(output)
    return output
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="treatmentAvg-custom-tooltip">
          <p>{`${lang === "English" ? "Treatment" : "Traitement"} : ${payload[0].payload.originalName}`}</p>
          <p>{`${lang === "English" ? "Number" : "Numéro"} : ${payload[0].payload.occurrences}`}</p>
        </div>
      )
    }
    return null;
  }

  const CustomTooltip2 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="treatmentAvg2-custom-tooltip">
          <p>{`${lang === "English" ? "Treatment" : "Traitement"} : ${payload[0].payload.originalName}`}</p>
          <p>{`${lang === "English" ? "Number" : "Numéro"}: ${payload[0].payload.occurrences}`}</p>
        </div>
      )
    }
    return null;
  }

  const CustomTooltip3 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="treatmentAvg3-custom-tooltip">
          <p>{`${lang === "English" ? "Treatment" : "Traitement"} : ${payload[0].payload.originalName}`}</p>
          <p>{`${lang === "English" ? "Number" : "Numéro"}: ${payload[0].payload.occurrences}`}</p>
        </div>
      );
    }
    return null;
  };

  const handleSubHeaderAdminNav = () => {
    if (saved) {
      props.history.push(`/adminPanel/users`)
    } else {
      setNavBy('admin')
      setDisplayNavDialog(true)
    }
  }

  const handleSubHeaderProgressNav = () => {
    Event('Subheader', 'click', 'Track Progress');
    if (saved) {
      props.history.push(`/userPanel/average`)
    } else {
      setNavBy('user')
      setDisplayNavDialog(true)
    }
  }

  const handleSubHeaderLogout = () => {
    // Event('Subheader', 'click', 'Logout');
    const Event = new CustomEvent('Subheader', { detail: { action: 'click', label: 'Logout' } });
    if (saved) {
      logout()
      props.history.push(`/`)
    } else {
      setNavBy('logout')
      setDisplayNavDialog(true)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getUser()
    if (user) getLogs()
  }, [user])

  const handleDate1Used = (event) => {
    setDate1Used(event.target.value);
  }

  const handleDate2Used = (event) => {
    setDate2Used(event.target.value);
  }
  const handlePredefinedRangeChangeForUsed = (e) => {
    const selectedOption = e.target.value;
    const predefinedOption = predefinedDateRanges.find(option => option.label === selectedOption);
    if (predefinedOption) {
      const [startDate, endDate] = predefinedOption.range();
      setDate1Used(startDate.toISOString().split('T')[0]);
      setDate2Used(endDate.toISOString().split('T')[0]);
    }
  };
  
  
  
  useEffect(() => {
    if (date1Used === "default") {
      setDate2Used("default");
    } else {
      if (date2Used === "default") {
        setDate2Used(usedDates[usedDates.length - 1]);
      } else {
        onClickUsed(date1Used, date2Used, rawUsedLogs)
      }
    }
  }, [date1Used]);

  useEffect(() => {
    if (date2Used === "default") {
      setUsedLimited(usedExport);
    } else {
      onClickUsed(date1Used, date2Used, rawUsedLogs);
    }
  }, [date2Used]);

  const onClickUsed = (date1, date2, logs) => {
    if (date1 === "default" || date2 === "default") {
      return;
    }
    let dateRange = []
    var d1 = Date.parse(date1)
    var d2 = Date.parse(date2)
    logs.map(el => {
      var logDate = Date.parse(el.date)
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el)
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el)
      }
    })

    let counts = []
    for (var i = 0; i < dateRange.length; i++) {
      for (var j = 0; j < dateRange[i].used.length; j++) {
        if (dateRange[i].used.length !== 0) {
          counts.push(dateRange[i].used[j])
        }
      }
    }

    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output
    setUsedLimited(output)
  }


  const handleDate1Med = (event) => {
    setDate1Med(event.target.value);
  }

  const handleDate2Med = (event) => {
    setDate2Med(event.target.value);
  }
  const handlePredefinedRangeChangeForMed = (e) => {
    const selectedOption = e.target.value;
    const predefinedOption = predefinedDateRanges.find(option => option.label === selectedOption);
    if (predefinedOption) {
      const [startDate, endDate] = predefinedOption.range();
      setDate1Med(startDate.toISOString().split('T')[0]);
      setDate2Med(endDate.toISOString().split('T')[0]);
    }
  };

  useEffect(() => {
    if (date1Med === "default") {
      setDate2Med("default");
    } else {
      if (date2Med === "default") {
        setDate2Med(medDates[medDates.length - 1]);
      } else {
        onClickMed(date1Med, date2Med, rawMedLogs)
      }
    }
  }, [date1Med]);

  useEffect(() => {
    if (date2Med === "default") {
      setMedLimited(medExport);
    } else {
      onClickMed(date1Med, date2Med, rawMedLogs);
    }
  }, [date2Used]);

  const onClickMed = (date1, date2, logs) => {
    if (date1 === "default" || date2 === "default") {
      return;
    }
    let dateRange = []
    var d1 = Date.parse(date1)
    var d2 = Date.parse(date2)
    logs.map(el => {
      var logDate = Date.parse(el.date)
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el)
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el)
      }
    })

    let counts = []
    for (var i = 0; i < dateRange.length; i++) {
      for (var j = 0; j < dateRange[i].med.length; j++) {
        if (dateRange[i].med.length !== 0) {
          counts.push(dateRange[i].med[j])
        }
      }
    }

    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output
    setMedLimited(output)
  }


  const handleDate1Planned = (event) => {
    setDate1Planned(event.target.value)
  }

  const handleDate2Planned = (event) => {
    setDate2Planned(event.target.value)
  }
  const handlePredefinedRangeChangeForPlanned = (e) => {
    const selectedOption = e.target.value;
    const predefinedOption = predefinedDateRanges.find(option => option.label === selectedOption);
    if (predefinedOption) {
      const [startDate, endDate] = predefinedOption.range();
      setDate1Planned(startDate.toISOString().split('T')[0]);
      setDate2Planned(endDate.toISOString().split('T')[0]);
    }
  };

 
  useEffect(() => {
    if (date1Planned === "default") {
      setDate2Planned("default");
    } else {
      if (date2Planned === "default") {
        setDate2Planned(plannedDates[plannedDates.length - 1]);
      } else {
        onClickPlanned(date1Planned, date2Planned, rawPlannedLogs)
      }
    }
  }, [date1Planned])

  useEffect(() => {
    if (date2Planned === "default") {
      setPlannedLimit(plannedExport);
    } else {
      onClickPlanned(date1Planned, date2Planned, rawPlannedLogs);
    }
  }, [date2Planned]);

  // Transform the data for abbreviation
  const transformedData = usedLimited.map(item => {
    
    return {
        ...item,
        originalName: item.name || "Unknown", // Provide a fallback name
        name: item.name ? transformName(item.name, nameMap) : "Unknown" // Use fallback for missing names
    };
});


  const transformedMedData = medLimited.map(item => {
    
    return {
        ...item,
        originalName: item.name || "Unknown", // Provide a fallback name
        name: item.name ? transformName(item.name, nameMap) : "Unknown" // Use fallback for missing names
    };
});


  const transformedPlannedData = plannedLimit.map(item => {
    return {
        ...item,
        originalName: item.name || "Unknown", // Provide a fallback name
        name: item.name ? transformName(item.name, nameMap) : "Unknown" // Use fallback for missing names
    };
});

  
  const onClickPlanned = (date1, date2, logs) => {
    if (date1 === "default" || date2 === "default") {
      return;
    }
    let dateRange = []
    var d1 = Date.parse(date1)
    var d2 = Date.parse(date2)
    logs.map(el => {
      var logDate = Date.parse(el.date)
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el)
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el)
      }
    })

    let counts = []
    for (var i = 0; i < dateRange.length; i++) {
      for (var j = 0; j < JSON.parse(dateRange[i].planned).length; j++) {
        if (JSON.parse(dateRange[i].planned)[j] !== null) {
          counts.push(JSON.parse(dateRange[i].planned)[j])
        }
      }
    }
    var output = Object.values(counts.reduce((obj, { name }) => {
      if (obj[name] === undefined)
        obj[name] = { name: name, occurrences: 1 };
      else
        obj[name].occurrences++;
      return obj;
    }, {}));
    setPlannedLimit(output)
  }


  const colorList = ["#ff0000", "#ffa500", "#ffff00", "#00ff00", "#00ffff", "#0000ff", "#800080",]

  function colorFill(index, parts){
    if(parts>0){
    const value = Math.floor(255/parts)*65536
    var result = (255 - Math.floor(255/parts)*(index + 1) + value*(index + 1)).toString(16)

    while(result.length < 6){
      result = "0" + result
    }

    return "#" + result
  }else{return "#0000ff"}
  }

function CustomizedTick(props) {
  const { x, y, payload } = props;

  // Use the payload value directly
  const info = payload.value.toString();

  // Create a helper function to measure word width using the Canvas API
  const measureTextWidth = (text, font = "bold 12px Arial") => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context) {
          context.font = font;
          return context.measureText(text).width;
      }
      return 0;
  };

  // Dynamically calculate word positions
  let accumulatedWidth = 0;

  const tspans = info.split(" ").map((word, index) => {
      const wordWidth = measureTextWidth(word, "bold 12px Arial"); // Measure actual word width
      const currentX = accumulatedWidth; // Position this word after the previous one
      accumulatedWidth += wordWidth + 8; // Add padding between words

      return (
          <tspan
              key={index}
              style={{ fontWeight: "bold" }}
              fontSize={12} // Adjust size as needed
              x={currentX} // Set individual x for each word
              dy="0" // Keep baseline alignment
          >
              {word}
          </tspan>
      );
  });

  return (
      <g transform={`translate(${x},${y})`}>
          <text transform="rotate(70)" fill="#666">
              {tspans}
          </text>
      </g>
  );
}

  return (
    <>
      <IdleComponent history={props.history} />
      <div className="user-container">
        <div className='page-header'>
          <Header current={props} handleNav={handleStepperNav} setNavTo={setNavTo} />
        </div>
        <div className="page-subheader">
        <Subheader title={lang === "English" ? "Track pain" : "Suivre la douleur"}
          handleAdmin={handleSubHeaderAdminNav}
          handleUser={handleSubHeaderProgressNav}
          handleLogout={handleSubHeaderLogout}
        />
      </div>
        <div className='user-nav'>
          <UserNav />
        </div>
        <div className="user-body">
        <div style={{ display: 'flex', marginTop: 'auto', padding: "1rem", alignItems: "center", justifyContent: "space-between" }}>
  <Button className="next-btn" tag={RRNav} to="/userPanel/manage">
    {lang === "English" ? "Back" : "Retour"}
  </Button>
  <Button className="next-btn" tag={RRNav} to="/summary">
    {lang === "English" ? "Next" : "Suivant(e)"}
  </Button>
</div>
          <div className="captions">
            <h3><b>{lang === "English" ? "Planned and used treatments" : "Traitements prévus et utilisés"}</b></h3>
            <p>{lang === "English" ? "Here you can review how many times you have added treatments to your plan and used certain treatments." :
              "Ici vous pouvez voir combien de fois vous avez ajouté des traitements à votre plan et utilisé certains traitements."}</p>
            <p>{lang === "English" ? "The first graph shows how many times you have added a treatment to your plan using the JIA Option Map." :
              "Le premier graphique montre combien de fois vous avez ajouté un traitement à votre plan à l'aide de la Carte d’options en AJI."}</p>
            <p>{lang === "English" ? "The second and the third graphs show how many times you've used the treatments you planned." :
              "Le deuxième graphique montre combien de fois vous avez utilisé les traitements que vous aviez planifiés."}</p>
            <p>{lang === "English" ? "This will let you know which treatments you have used the most!" :
              "Cela vous permettra de savoir quels traitements vous avez utilisés le plus!"}</p>
            <br></br>
          </div>

          <div className={"chartWrapper"}>

            <div style ={{visibility:"hidden"}}>{fontLimit = Math.max(plannedLimit.length, usedLimited.length, medLimited.length)}</div>
            <div style ={{visibility:"hidden"}}>{usedElements = usedLimited.length}</div>
            <div style ={{visibility:"hidden"}}>{medElements = medLimited.length}</div>
            <div style ={{visibility:"hidden"}}>{plannedElements = plannedLimit.length}</div>

            <div className="t3">
              <p><b>{lang === "English" ? "Treatments Planned" : "Traitements prévus"}</b></p>
            </div>

            <div className={"intervalDropdown"}>
              <div className={lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"}>
                <select disabled={isLoading} onChange={handleDate1Planned} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                  {plannedDates !== undefined && plannedDates.length !== 0 && plannedDates.map((list) => <option key={list} value={list} selected={list === date1Planned}>{list}</option>)}
                </select>
              </div>

              <div className={lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"}>
                <select disabled={isLoading} onChange={handleDate2Planned} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                  {plannedDates !== undefined && plannedDates.length !== 0 && plannedDates.map((list) => <option key={list} value={list} selected={list === date2Planned}>{list}</option>)}
                </select>
              </div>
                <div className={lang === "English" ? "chooseTimes_en" : "chooseTimes_fr"}>
                  <select disabled={isLoading} onChange={handlePredefinedRangeChangeForPlanned} className="select_date">
                    <option value="default">{lang === "English" ? "-- Select a date range --" : "-- Sélectionnez une plage de dates --"}</option>
                    {predefinedDateRanges.map((range) => (<option key={range.label} value={range.label}>{range.label}</option>))}                  
                  </select>
                </div>
            </div>
            
            <ResponsiveContainer width="95%" height={400}>
              <BarChart className="bar" data={transformedPlannedData} margin={{ top: 5, right: 30, left: 30, bottom: 150 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis width={20} tick={<CustomizedTick />} dataKey="name" interval={0} angle={-45} label={{ value: lang === "English" ? 'Treatment' : 'Traitement', position: "bottom", offset: 120 }} />
                <YAxis dataKey="occurrences" allowDecimals={false} label={{ value: lang === "English" ? "Times Used" : "Traitements utilisés", position: "insideLeft", angle: -90 }} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="occurrences" fill="#1f417e" maxBarSize={50}>
                  {
                    transformedPlannedData.map((entry, index) => (
                      <Cell fill={colorFill(index, plannedElements)} />
                    ))
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>

          </div>


          <div className={"chartWrapper"}>
            <hr></hr><div className="t4">
              <p><b>{lang === "English" ? "Treatments Used" : "Traitements utilisés"}</b></p>
            </div>

            <hr></hr><div className="t4">
              <p><b>{lang === "English" ? "1. Prescribed Arthritis Medication" : "1. Médicaments contre l'arthrite prescrits"}</b></p>
            </div>

            <div className={"intervalDropdown"}>
              <div className={lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"}>
                <select disabled={isLoading} onChange={handleDate1Med} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                  {medDates !== undefined && medDates.length !== 0 && medDates.map((list) => <option key={list} value={list} selected={list === date1Med}>{list}</option>)}
                </select>
              </div>

              <div className={lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"}>
                <select disabled={isLoading} onChange={handleDate2Med} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                  {medDates !== undefined && medDates.length !== 0 && medDates.map((list) => <option key={list} value={list} selected={list === date2Med}>{list}</option>)}
                </select>
              </div>
                <div className={lang === "English" ? "chooseTimes_en" : "chooseTimes_fr"}>
                  <select disabled={isLoading} onChange={handlePredefinedRangeChangeForMed} className="select_date">
                    <option value="default">{lang === "English" ? "-- Select a date range --" : "-- Sélectionnez une plage de dates --"}</option>
                    {predefinedDateRanges.map((range) => (<option key={range.label} value={range.label}>{range.label}</option>))}                  
                  </select>
                </div>
            </div>

            <ResponsiveContainer width="95%" height={400}>
              <BarChart className="bar" data={transformedMedData} margin={{ top: 5, right: 30, left: 30, bottom: 160 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis width={20} tick={<CustomizedTick />} dataKey="name" interval={0} angle={-45} label={{ value: lang === "English" ? 'Treatment' : 'Traitement', position: "bottom", offset: 130 }} />
                <YAxis dataKey="occurrences" allowDecimals={false} label={{ value: lang === "English" ? "Times Used" : "Traitements utilisés", position: "insideLeft", angle: -90 }} />
                <Tooltip content={<CustomTooltip2 />} />
                <Bar dataKey="occurrences" fill="#1f417e" maxBarSize={50}>
                  {
                    transformedData.map((entry, index) => (
                      <Cell fill={colorFill(index, medElements)} />
                    ))
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>


            <hr></hr><div className="t4">
              <p><b>{lang === "English" ? "2. Other Treatments" : "2. Autres Traitements"}</b></p>
            </div>

            <div className={"intervalDropdown"}>
              <div className={lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"}>
                <select disabled={isLoading} onChange={handleDate1Used} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                  {usedDates !== undefined && usedDates.length !== 0 && usedDates.map((list) => <option key={list} value={list} selected={list === date1Used}>{list}</option>)}
                </select>
              </div>

              <div className={lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"}>
                <select disabled={isLoading} onChange={handleDate2Used} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                  {usedDates !== undefined && usedDates.length !== 0 && usedDates.map((list) => <option key={list} value={list} selected={list === date2Used}>{list}</option>)}
                </select>
              </div>
                <div className={lang === "English" ? "chooseTimes_en" : "chooseTimes_fr"}>
                  <select disabled={isLoading} onChange={handlePredefinedRangeChangeForUsed} className="select_date">
                    <option value="default">{lang === "English" ? "-- Select a date range --" : "-- Sélectionnez une plage de dates --"}</option>
                    {predefinedDateRanges.map((range) => (<option key={range.label} value={range.label}>{range.label}</option>))}                  
                  </select>
                </div>
            </div>

            
            <ResponsiveContainer width="95%" height={400}>
              <BarChart className="bar" data={transformedData} margin={{ top: 5, right: 30, left: 30, bottom: 160 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis width={20} tick={<CustomizedTick />} dataKey="name" interval={0}  angle={-45}  textAnchor="end"  label={{ value: lang === "English" ? 'Treatment' : 'Traitement', position: "bottom", offset: 130 }} />
                <YAxis dataKey="occurrences" allowDecimals={false} label={{ value: lang === "English" ? "Times Used" : "Traitements utilisés", position: "insideLeft", angle: -90  }} />
                <Tooltip content={<CustomTooltip3 />} />
                <Bar dataKey="occurrences" fill="#1f417e" maxBarSize={50}>
                  {
                    transformedData.map((entry, index) => (
                      <Cell fill={colorFill(index, usedElements)} />
                    ))
                  }

                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

        </div>
      </div>
      <BottomTabNavReview current={props} />
<div style={{ display: 'flex', marginTop: 'auto', padding: "1rem", alignItems: "center", justifyContent: "space-between" }}>
  <Button className="next-btn" tag={RRNav} to="/userPanel/manage">
    {lang === "English" ? "Back" : "Retour"}
  </Button>
  <Button className="next-btn" tag={RRNav} to="/summary">
    {lang === "English" ? "Next" : "Suivant(e)"}
  </Button>
</div>
    </>
  )
}

export default TreatmentAvg
