import React from 'react'
import { NavLink } from 'react-router-dom';
import './UserNav.css'
import { Event } from '../GoogleAnalytics/GA';

//Get value of language from local storage
let lang = localStorage.getItem("language")

const UserNav = props => {
  const handleUserState = () => {
    if (props.onCloseUser) props.onCloseUser(false, null)
  }

  return (
    // <div className="side-nav-container-user">
      <div className="fixed-content-user">
        {/* <div className="side-header-user">
          <h3>{lang === "English" ? "User Dashboard" : "Tableau de bord"}</h3>
        </div> */}
        <div className='list-container-user'>
          <ul className="list-user">

            <li className="list-user-item" onClick={() => Event('UserNav', 'click', 'Pain over time')}><NavLink to="/userPanel/average" onClick={handleUserState}>{lang === "English" ? "Pain over time" : "Douleur à travers le temps"}</NavLink></li>
            <li className="list-user-item" onClick={() => Event('UserNav', 'click', 'Impact of treatment')}><NavLink to="/userPanel/manage">{lang === "English" ? "Impact of treatment" : "Impact du traitement"}</NavLink></li>
            <li className="list-user-item" onClick={() => Event('UserNav', 'click', 'Planned and used treatments')}><NavLink to="/userPanel/treatments">{lang === "English" ? "Planned and used treatments" : "Traitements prévus et utilisés"}</NavLink></li>

            {/* <ul>
              <li><NavLink to="/userPanel/summary">Summaries</NavLink></li><hr></hr>
            </ul> */}

          </ul>

        </div>

      </div>
    // </div>
  )

}

export default UserNav
